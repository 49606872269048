<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
export default {
  name: "GraficaNovedadesInspeccionesItem",
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
          colors: [
          "#ff1c79",
          "#001871",
          "#ba4477",
          "#4b3074",
          "#c84778",
          "#DB843D",
          "#828282",
          "#444444",
          "#000000",
        ],
        chart: {
          type: "bar",
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "CANTIDAD DE NOVEDADES POR ITEM DE INSPECCIÓN",
          align: "center",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "Novedades Inspecciones Item",
          },
        },
        exporting: {
          enabled: true,
        },
        tooltip: {
          valueSuffix: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },
        series: [
          {
            type: "bar",
            data: [],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#ff1c79"],
                [1, "#001871"],
              ],
            },
          },
        ],
      },
      respuesta: [],
    };
  },
  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/hidrocarburos/dashboard/graficaNovedadesInspeccionesItem", {
          params: this.$parent.filter,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaNovedadesInspeccionesItem();
        });
    },

    async graficaNovedadesInspeccionesItem() {
      let novedades = [];
      let categorias = [];
      for (let i = 0; i < this.respuesta.length; i++) {
        categorias.push(this.respuesta[i].nombre);
        novedades.push(parseFloat(this.respuesta[i].cumple));
      }
      this.chartOptions.xAxis.categories = categorias;
      this.chartOptions.series[0].data = novedades;
      this.chartOptions.series[0].name = "Novedades";
    },
  },

  mounted() {
    this.getGrafica();
  },
};
</script>